import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
axios.defaults.baseURL = '//teleservice-zengzhidianxin-vsjnqhvgvl.cn-hangzhou.fcapp.run/zzdxindex'
Vue.prototype.axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.directive('title', {
  inserted: function (el, binding) {
    if (el.dataset.title) {
      document.title = el.dataset.title
    } else {
      document.title = '加载中...'
    }
  }
})
/// ///////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
