import Vue from 'vue'
import VueRouter from 'vue-router'
const indexHome = () => import('@/views/indexHome')
const helpDocs = () => import('@/views/helpDocs')
const appTool = () => import('@/views/appTool')
// const indexMain = () => import('@/views/indexMain')
// const indexInfo = () => import('@/views/indexInfo')
// const docFile = () => import('@/views/files/docFile')
// const listInfo = () => import('@/views/info/listInfo')
// const showInfo = () => import('@/views/info/showInfo')
// const userHome = () => import('@/views/member/userHome')
const userLogin = () => import('@/views/member/userLogin')
// const userReg = () => import('@/views/member/userReg')
// const userPass = () => import('@/views/member/userPass')
const userCenter = () => import('@/views/member/userCenter')
const userMain = () => import('@/views/member/userMain')
const userOrder = () => import('@/views/member/order/userOrder')
// const userPay = () => import('@/views/member/pay/userPay')
const appTest = () => import('@/views/appTest')
Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    component: appTest
  },
  {
    path: '/index',
    component: indexHome
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/help',
    component: helpDocs
  },
  {
    path: '/tools',
    component: appTool
  },
  {
    path: '/login',
    component: userLogin
  },
  // {
  //   path: '/test',
  //   component: appTest
  // },

  {
    path: '/usercenter',
    component: userCenter,
    redirect: '/userorder',
    children: [
      {
        path: '/main',
        component: userMain
      },
      {
        path: '/userorder',
        component: userOrder
      }
      //     {
      //       path: '/userpay',
      //       component: userPay
      //     }
    ]
  }

  // {
  //   path: '/index',
  //   component: indexMain,
  //   // redirect: '/listInfo',
  //   children: [
  //     {
  //       path: '/index',
  //       component: indexInfo
  //     },
  //     {
  //       path: '/listinfo',
  //       component: listInfo
  //     },
  //     {
  //       path: '/listinfo/:keyword',
  //       component: listInfo
  //     },
  //     {
  //       path: '/showinfo/:id',
  //       component: showInfo
  //     },
  //     {
  //       path: '/docfile/:id',
  //       component: docFile
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  routes
})

// 解决同一路由加载两遍报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

// 路由导航卫士
router.beforeEach((to, from, next) => {
  // if (to.path == '/login') {
  //   return next()
  // }
  // const token = window.sessionStorage.getItem('token')
  // if (!token) {
  //   return next({ path: '/login' })
  // }
  // next()

  // const s1 = document.createElement('script')
  // s1.type = 'text/javascript'
  // s1.src = '//pv.sohu.com/cityjson?ie=utf-8'
  // document.body.appendChild(s1)
  // const s2 = document.createElement('script')
  // s2.type = 'text/javascript'
  // s2.src = '//pyui.oss-cn-hangzhou.aliyuncs.com/js/stat.js'
  // document.body.appendChild(s2)
  // //移除
  // document.body.removeChild(s1)
  // document.body.removeChild(s2)
  /// //////////////////////////////////////////

  /// //////////////////////////////////////////
  next()
})
